import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ComponentWrapper,
  MarkdownRenderer,
  Button,
  Grid,
} from 'ascential-horizon'
import { StyledForm, StyledCheckbox, Wrapper } from './StatementForm.style'

function StatementForm({
  transparencyStatement,
  successMessage,
  buttonLabel,
  sponsorName,
  callback,
}) {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const statement =
    transparencyStatement &&
    transparencyStatement.childMarkdownRemark &&
    transparencyStatement.childMarkdownRemark.html

  // when there is no statement, the checkbox should be hidden and user automatically opts in.
  const isChecked = !!statement
  const [checked, setChecked] = useState(!isChecked)

  const defaultStatement = `By clicking on the button below you agree that we may share your details with ${sponsorName} who may contact you after MoneyFest by Money20/20 about their products and services`
  const successMsg =
    (successMessage &&
      successMessage.childMarkdownRemark &&
      successMessage.childMarkdownRemark.html) ||
    ''

  const label = buttonLabel || 'AGREE'
  function handleClick() {
    setChecked(!checked)
  }

  function handleSubmit() {
    setLoading(!loading)
    callback()
    setSubmitted(!submitted)
  }

  return (
    <Wrapper>
      <ComponentWrapper columnWidth={12}>
        <StyledForm>
          {submitted ? (
            <MarkdownRenderer html={successMsg} />
          ) : (
            <>
              <Grid mx={0} flexWrap="inherit">
                {isChecked && (
                  <StyledCheckbox
                    id="input-check"
                    onClick={handleClick}
                    defaultChecked={checked}
                    type="checkbox"
                  />
                )}
                <MarkdownRenderer html={statement || defaultStatement} />
              </Grid>
              <Button
                disabled={!checked || loading}
                onClick={handleSubmit}
                isChecked={isChecked}>
                {label}
              </Button>
            </>
          )}
        </StyledForm>
      </ComponentWrapper>
    </Wrapper>
  )
}

StatementForm.propTypes = {
  transparencyStatement: PropTypes.shape(PropTypes.obj),
  successMessage: PropTypes.shape(PropTypes.obj),
  buttonLabel: PropTypes.string,
  sponsorName: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}

StatementForm.defaultProps = {
  buttonLabel: null,
  transparencyStatement: null,
  successMessage: null,
}

export default StatementForm
