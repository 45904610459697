import styled from '@emotion/styled'

export const Wrapper = styled('div')(({ theme: { space } }) => ({
  paddingTop: space.large,
}))

export const StyledForm = styled('div')(({ theme: { colors, space } }) => ({
  background: colors.offwhite,
  borderRadius: '3px',
  padding: space.xlarge,
  minHeight: '8rem',
  p: {
    margin: 0,
  },
  button: {
    marginTop: space.large,
  },
}))

export const StyledButton = styled('div')(
  ({ theme: { mediaQueries }, isChecked }) => ({
    [mediaQueries.medium]: {
      marginLeft: isChecked ? '2.4rem' : 0,
    },
  })
)

export const StyledCheckbox = styled('input')({
  cursor: 'pointer',
  marginRight: '1rem',
})
