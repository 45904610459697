import React from 'react'
import { FeaturePanel } from 'ascential-horizon'
import SponsorFeaturePanel from '../../components/SponsorFeaturePanel'
import { buildFeaturePanelProps } from '../../components/FeaturePanel/FeaturePanel.helper'
import { trackBtnClick } from '../../services/tracking'

const renderFeaturePanel = (content, key, userDetails) => {
  const { ctaBehindLogin, gatedSponsorContent, ...rest } = content
  const { actions, isAuthorized, user } = userDetails

  if (gatedSponsorContent) {
    return (
      <SponsorFeaturePanel
        key={key.toString()}
        content={content}
        compKey={key.toString()}
        userDetails={userDetails}
      />
    )
  }
  function handleClick(e, label, url) {
    const loggedIn = !!user.loggedIn
    if (ctaBehindLogin && !isAuthorized) {
      e.preventDefault()
    }

    if (ctaBehindLogin && !loggedIn) {
      return actions.login()
    }

    return trackBtnClick(label, url)
  }

  const baseProps = {
    ...rest,
    ctaProps: {
      ...rest.ctaProps,
      disabled: user.loggedIn && !isAuthorized,
    },
  }

  const featurePanelProps = buildFeaturePanelProps(baseProps, handleClick)

  return <FeaturePanel key={key.toString()} {...featurePanelProps} />
}

export default renderFeaturePanel
