import React from 'react'
import { SectionHeader } from 'ascential-horizon'
import { trackBtnClick } from '../../services/tracking'

const renderSectionHeader = (content, key, userDetails) => {
  const {
    instanceName,
    title,
    copy,
    primaryCtaLabel,
    primaryCtaUrl,
    secondaryCtaLabel,
    secondaryCtaUrl,
    position,
    textSize,
    ctaBehindLogin,
  } = content
  const { actions, isAuthorized, user } = userDetails
  const html = copy && copy.childMarkdownRemark && copy.childMarkdownRemark.html

  function handleClick(e, label, url) {
    const loggedIn = !!user.loggedIn

    if (ctaBehindLogin && !isAuthorized) {
      e.preventDefault()
    }

    if (ctaBehindLogin && !loggedIn) {
      return actions.login()
    }

    return trackBtnClick(label, url)
  }

  const sectionHeaderProps = {
    id: instanceName,
    title,
    position,
    textSize,
    copy: html,
    primaryCta: primaryCtaLabel &&
      primaryCtaUrl && {
        disabled: user && user.loggedIn && !isAuthorized,
        label: primaryCtaLabel,
        url: primaryCtaUrl,
        onClick: (e) => handleClick(e, primaryCtaLabel, primaryCtaUrl),
      },
    secondaryCta: secondaryCtaLabel &&
      secondaryCtaUrl && {
        disabled: user && user.loggedIn && !isAuthorized,
        label: secondaryCtaLabel,
        url: secondaryCtaUrl,
        onClick: (e) => handleClick(e, primaryCtaLabel, primaryCtaUrl),
      },
  }
  if (!sectionHeaderProps) {
    return null
  }
  return <SectionHeader key={key.toString()} {...sectionHeaderProps} />
}

export default renderSectionHeader
