export const buildFeaturePanelProps = (
  {
    videoUrl,
    copy,
    image,
    title,
    variant,
    textPosition,
    ctaLabel,
    ctaUrl,
    previousPrice,
    currentPrice,
    ctaProps,
    instanceName,
  },
  clickFn
) => {
  const imageProps =
    image && !image.fluid ? { ...image, src: image.file.url } : image

  const html = copy && copy.childMarkdownRemark && copy.childMarkdownRemark.html

  const mediaProps = videoUrl
    ? {
        video: {
          url: videoUrl,
          aspectRatio: '16:9',
        },
      }
    : {
        image: imageProps || { src: '/' },
      }

  const featurePanelProps = {
    id: instanceName,
    title: title || '',
    copy: html || '',
    variant: variant || 'primary',
    textPosition: textPosition || 'right',
    cta:
      (ctaLabel &&
        ctaUrl && {
          label: ctaLabel || '',
          url: ctaUrl || '',
          onClick: (e) => clickFn(e, ctaLabel, ctaUrl),
          ...ctaProps,
        }) ||
      '',
    price: previousPrice &&
      currentPrice && {
        previous: previousPrice || '',
        current: currentPrice || '',
      },
    ...mediaProps,
  }

  return featurePanelProps
}

export default buildFeaturePanelProps
