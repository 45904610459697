/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FeaturePanel } from 'ascential-horizon'
import { trackBtnClick } from '../../services/tracking'
import StatementForm from './StatementForm'
import MainMarketoForm from '../MarketoForm'
import { buildFeaturePanelProps } from '../FeaturePanel/FeaturePanel.helper'

const MARKETO_FORM_ID = '1427'

const SponsorFeaturePanel = ({ content, compKey, userDetails }) => {
  const { ctaBehindLogin, gatedSponsorContent, ...rest } = content
  const [showStatement, setStatement] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)

  function handleClick(e, label, url) {
    const { user, actions } = userDetails
    const loggedIn = !!user.loggedIn

    if (ctaBehindLogin && !loggedIn) {
      e.preventDefault()
      actions.login()
    } else {
      trackBtnClick(label, url)
      setStatement(true)
      e.preventDefault()
    }
  }

  const formValues = {
    Email: userDetails.user.email,
    sponsorName: gatedSponsorContent.sponsorName,
    sponsorContentOptIn: true,
    assetURL: gatedSponsorContent.assetUrl,
  }

  const props = { ...rest, ctaProps: { disabled: showStatement } }

  const featurePanelProps = buildFeaturePanelProps(props, handleClick)

  return (
    <div>
      <FeaturePanel key={compKey.toString()} {...featurePanelProps} />
      {showStatement && (
        <>
          <MainMarketoForm
            key={`marketo_${compKey.toString()}`}
            formId={MARKETO_FORM_ID}
            values={formValues}
            submit={submitForm}
            isHidden
          />
          <StatementForm
            callback={() => setSubmitForm(true)}
            {...gatedSponsorContent}
          />
        </>
      )}
    </div>
  )
}

export default SponsorFeaturePanel
